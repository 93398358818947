
import { Component, Vue } from 'nuxt-property-decorator'
import { Events } from '@simple-payments/shared-packages/types/enum/Events'

@Component
export default class extends Vue {
  mounted () {
    const eventBus = this.$eventBus

    /** преход к поиску */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    eventBus.$on(Events.SEARCH_FORM_SUBMIT, () => {})

    /** посадка с письма */
    eventBus.$on(Events.EMAIL_RESULT, () => {})

    /** посадка с Гибдд */
    eventBus.$on(Events.GIBDD_RESULT, () => {})

    /** офомление подписки */
    eventBus.$on(Events.SUBSCRIBE, () => {})

    eventBus.$on(Events.PAYMENT_FRAME_LOADED, () => {})

    /** успешная оплата */
    eventBus.$on(Events.PAYMENT_SUCCESS, async () => {})

    /** Ошибка при оплате */
    eventBus.$on(Events.PAYMENT_FAIL, async () => {})

    /** подписка на браузерные пуши */
    eventBus.$on(Events.PUSH_SUBSCRIBE, () => {})

    /** ввод данных пользователя */
    eventBus.$on(Events.PAYER_INFO_SUBMIT, () => {})
  }
}
