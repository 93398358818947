import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3ddcc717 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _05cbd3f5 = () => interopDefault(import('../pages/car-tax-landing.vue' /* webpackChunkName: "pages/car-tax-landing" */))
const _6f10d446 = () => interopDefault(import('../pages/ctc-not-found.vue' /* webpackChunkName: "pages/ctc-not-found" */))
const _1d16aac2 = () => interopDefault(import('../pages/email-landing.vue' /* webpackChunkName: "pages/email-landing" */))
const _2138da62 = () => interopDefault(import('../pages/error-gibdd-incoming.vue' /* webpackChunkName: "pages/error-gibdd-incoming" */))
const _d97b03ac = () => interopDefault(import('../pages/gibdd-incoming.vue' /* webpackChunkName: "pages/gibdd-incoming" */))
const _107eac7f = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _7de72748 = () => interopDefault(import('../pages/payment-fail.vue' /* webpackChunkName: "pages/payment-fail" */))
const _fb793c74 = () => interopDefault(import('../pages/payment-history.vue' /* webpackChunkName: "pages/payment-history" */))
const _21af6db5 = () => interopDefault(import('../pages/payment-success.vue' /* webpackChunkName: "pages/payment-success" */))
const _76e67aa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _72c92716 = () => interopDefault(import('../pages/saved-cards.vue' /* webpackChunkName: "pages/saved-cards" */))
const _32dfe183 = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _1366216c = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _374539db = () => interopDefault(import('../pages/search/fns.vue' /* webpackChunkName: "pages/search/fns" */))
const _410a3e20 = () => interopDefault(import('../pages/search/gibdd.vue' /* webpackChunkName: "pages/search/gibdd" */))
const _01debcea = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5a728c5c = () => interopDefault(import('../pages/articles/_alias.vue' /* webpackChunkName: "pages/articles/_alias" */))
const _4a61f494 = () => interopDefault(import('~/pages/email-landing' /* webpackChunkName: "" */))
const _ceaac300 = () => interopDefault(import('~/pages/index' /* webpackChunkName: "" */))
const _3877b402 = () => interopDefault(import('~/pages/unsubscribe' /* webpackChunkName: "" */))
const _7fd6f58f = () => interopDefault(import('../node_modules/@simple-payments/shared-packages/modules/auto-payment/pages/unsubscribe.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/articles",
    component: _3ddcc717,
    name: "articles"
  }, {
    path: "/car-tax-landing",
    component: _05cbd3f5,
    name: "car-tax-landing"
  }, {
    path: "/ctc-not-found",
    component: _6f10d446,
    name: "ctc-not-found"
  }, {
    path: "/email-landing",
    component: _1d16aac2,
    name: "email-landing"
  }, {
    path: "/error-gibdd-incoming",
    component: _2138da62,
    name: "error-gibdd-incoming"
  }, {
    path: "/gibdd-incoming",
    component: _d97b03ac,
    name: "gibdd-incoming"
  }, {
    path: "/payment",
    component: _107eac7f,
    name: "payment"
  }, {
    path: "/payment-fail",
    component: _7de72748,
    name: "payment-fail"
  }, {
    path: "/payment-history",
    component: _fb793c74,
    name: "payment-history"
  }, {
    path: "/payment-success",
    component: _21af6db5,
    name: "payment-success"
  }, {
    path: "/profile",
    component: _76e67aa2,
    name: "profile"
  }, {
    path: "/saved-cards",
    component: _72c92716,
    name: "saved-cards"
  }, {
    path: "/subscribe",
    component: _32dfe183,
    name: "subscribe"
  }, {
    path: "/unsubscribe",
    component: _1366216c,
    name: "unsubscribe"
  }, {
    path: "/search/fns",
    component: _374539db,
    name: "search-fns"
  }, {
    path: "/search/gibdd",
    component: _410a3e20,
    name: "search-gibdd"
  }, {
    path: "/",
    component: _01debcea,
    name: "index"
  }, {
    path: "/articles/:alias",
    component: _5a728c5c,
    name: "articles-alias"
  }, {
    path: "/email/:type(fssp|fns|gibdd)-landing",
    component: _4a61f494,
    name: "email-landing"
  }, {
    path: "/:type(fssp|fns|gibdd)/landing",
    component: _4a61f494
  }, {
    path: "/:type(by-licence-plate|by-sts|by-vu|by-uin)",
    component: _ceaac300
  }, {
    path: "/email/unsubscribe",
    component: _3877b402
  }, {
    path: "/autopayment/unsubscribe",
    component: _7fd6f58f,
    name: "autopayment-unsubscribe"
  }],

  parseQuery: (query) => require('qs').parse(query),
  stringifyQuery: (query) => {
    const result = require('qs').stringify(query);
    return result ? '?' + result : '';
  },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
